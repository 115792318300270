@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap')

#root
    height: 100vh
    .App
        height: 100vh

.full-height
    min-height: 100vh

.error-page 
    color: gray
    li
        list-style: none
        font-size: 1em
    h2 
        font-weight: 600
        font-size: 3em
