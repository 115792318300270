.paginationRow {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: end;
  gap: 10px;
}
.paginationRow button .content {
  display: flex;
  gap: 10px;
  align-items: center;
}/*# sourceMappingURL=pagination.css.map */