body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  background-color: white;
  height: 100vh;
}
body .loginForm {
  width: 100%;
  max-width: 400px;
  padding: 15px;
  margin: auto;
}
body .loginForm .loginFormHeader {
  width: 100%;
  max-width: 400px;
  padding: 15px;
  margin: auto;
}
body .loginForm .rightLink {
  float: right;
}
body .loginForm .leftLink {
  float: left;
}
body .loginForm .checkbox {
  overflow: hidden;
}
body .loginForm a {
  color: #4b7bec;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
body .loginForm a:hover {
  color: #00006D;
  text-decoration: none;
}
body .loginForm .error {
  margin-top: 15px;
  color: orangered;
}
body .loginForm .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
body .loginForm .form-control:focus {
  z-index: 2;
}
body .loginForm input {
  outline: none;
  background-color: #e6e6e6;
  margin-bottom: 15px;
  line-height: 35px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
body .loginForm input:focus {
  outline: none;
  background-color: #e6e6e6;
  border: 1px solid #827ffe;
  border-radius: 3px;
}
body .loginForm button {
  line-height: 35px;
  background-color: #4b7bec;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  width: 100%;
  color: white;
}
body .loginForm button:hover {
  background-color: #00006D;
}
body .loginForm .errorMessage {
  margin-top: 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: red;
}
body .loginForm .successMessage {
  margin-top: 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: green;
}
body .loginForm .sign-in-with-google-button {
  background-color: #4285F4;
  border-color: #4285F4;
  font-family: "Roboto", sans-serif;
  text-transform: none;
}
body .loginForm .sign-in-with-google-button:hover {
  background-color: #0d5bdc;
  border-color: #0d5bdc;
}
body .loginForm .sign-in-with-google-button .icon {
  height: 1.5em;
  margin-right: 0.2em;
  vertical-align: middle;
  margin-bottom: 2px;
}
body .loginForm .sign-in-with-apple-button {
  background-color: #000000;
  border-color: #000000;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  text-transform: none;
  font-size: 19px;
}
body .loginForm .sign-in-with-apple-button .icon {
  height: 1em;
  margin-right: 0.2em;
  vertical-align: middle;
  margin-bottom: 5px;
}
body .loginForm .sign-in-with-apple-button:hover {
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}/*# sourceMappingURL=auth.css.map */