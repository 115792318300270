body
  font-family: 'Open Sans', sans-serif
  font-weight: 400
  background-color: white
  height: 100vh
  .loginForm
    width: 100%
    max-width: 400px
    padding: 15px
    margin: auto
    .loginFormHeader
      width: 100%
      max-width: 400px
      padding: 15px
      margin: auto
    .rightLink
      float: right
    .leftLink
      float: left
    .checkbox
      overflow: hidden
    a 
      color: #4b7bec
      text-decoration: none
      font-family: 'Open Sans', sans-serif
      font-weight: 400
    a:hover
      color: #00006D
      text-decoration: none
    .error 
      margin-top: 15px
      color: orangered
    .form-control 
      position: relative
      box-sizing: border-box
      height: auto
      padding: 10px
      font-size: 16px
    .form-control:focus 
      z-index: 2
    input 
      outline: none
      background-color: #e6e6e6
      margin-bottom: 15px
      line-height: 35px
      font-family: 'Open Sans', sans-serif
      font-weight: 400
      
    input:focus 
      outline: none
      background-color: #e6e6e6
      border: 1px solid #827ffe
      border-radius: 3px
  
    button 
      line-height: 35px
      background-color: #4b7bec
      text-transform: uppercase
      font-family: 'Open Sans', sans-serif
      font-weight: 600
      width: 100%
      color: white
  
    button:hover 
      background-color: #00006D
  
    .errorMessage 
      margin-top: 10px
      font-family: 'Open Sans', sans-serif
      font-weight: 400
      color: red
  

    .successMessage 
      margin-top: 10px
      font-family: 'Open Sans', sans-serif
      font-weight: 400
      color: green


    .sign-in-with-google-button 
      background-color: #4285F4
      border-color: #4285F4
      font-family: 'Roboto', sans-serif
      text-transform: none

    .sign-in-with-google-button:hover 
      background-color: #0d5bdc
      border-color: #0d5bdc


    .sign-in-with-google-button .icon 
      height: 1.5em
      margin-right: 0.2em
      vertical-align: middle
      margin-bottom: 2px


    .sign-in-with-apple-button 
      background-color: #000000
      border-color: #000000
      font-family: -apple-system, BlinkMacSystemFont, sans-serif
      text-transform: none
      font-size: 19px
      .icon 
        height: 1em
        margin-right: 0.2em
        vertical-align: middle
        margin-bottom: 5px

    .sign-in-with-apple-button:hover 
      background-color: #4d4d4d
      border-color: #4d4d4d

