nav
    .logo
        height: 22px
        display: block
        margin: auto
.navbar-light
    a
        color: black
    .navbar-toggler
        border-color: transparent !important
.menu-spacer
    height: 56px
    background-color: transparent

.courseListContainer
    margin-top: 20px
    margin-bottom: 20px
    padding-bottom: 20px
    .courseTitle
        display: flex
        flex-direction: row
        gap: 5px
        align-items: center
        .courseName
            font-size: 1em
            font-weight: 500
        .courseCode
            .cc-badge
                color: black
                background-color: rgb(234, 236, 239) !important
                font-weight: 500
    .courseDate
        font-size: 0.8em
        font-weight: 400
    .courseOrganizer
        font-size: 0.8em
        font-weight: 400

.noCourses
    padding-top: 56px
    padding-left: 20px
    padding-right: 20px
    h2
      font-weight: 300
      font-size: 2em
.buttonRow
    margin-bottom: 10px
    .content
        display: flex
        justify-content: start
        gap: 10px
    .sort
        display: flex
        justify-content: end
        gap: 10px
        .datePickerButton
            white-space: nowrap
.spinnerRow
    margin: 50px
.scrollRow
    color: rgb(109, 116, 123)
    margin: 20px
.errorRow
    color: black
    margin: 50px
    text-align: center
    .errorCol
        ul
            padding: 0
            margin: 0
        li
            list-style-position: revert
            list-style-type: none
            font-size: 1em
            color: red
            word-wrap: break-word
        h2 
            font-weight: 600
            font-size: 2em
.usersListContainer
    margin-top: 20px
    margin-bottom: 20px
    padding-bottom: 20px
    .info
        font-size: 1em
        .email
            font-weight: 500
    .dates
        font-size: 0.8em
    .user-level
        font-size: 0.7em
.btn-group
    background-color: rgb(234, 236, 239)
    border-color: rgb(206, 212, 218)
    &:hover
        background-color: rgb(207, 211, 218)
        border-color: rgb(206, 212, 218)
    &:active
        background-color: rgb(207, 211, 218) !important
        border-color: rgb(206, 212, 218) !important
.search-bar
    width: 100%